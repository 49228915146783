.coinPage-Container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  height: 100vh;
  width: 100vw;
}

.coinPage-Info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 700px;
  background-color: rgb(0, 0, 0, 0.8);
  border-radius: 20px;
}

.coinPage-Info h1 {
  color: white;
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 700;
  color: #3aafa9;
  background-color: transparent;
}

.coinPage-Icon {
  height: 150px;
  margin: 30px 0px 30px 0px;
  background-color: transparent;
}

.coinPage-Data {
  display: flex;
  flex-direction: column;
  width: 80%;
  align-items: center;
  margin: 5px 0px 5px 0px;
  background-color: transparent;
}

.coinPage-Row {
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: center;
  margin: 5px 0px 5px 0px;
  background-color: transparent;
}

.coinPage-RowData {
  color: white;
  font-size: 25px;
  font-weight: 400;
  background-color: transparent;
}

.coinPage-RowHeader {
  width: 170px;
  font-size: 40px;
  color: white;
  font-size: 25px;
  font-weight: 600;
  background-color: transparent;
}

.red {
  color: #f00606;
}

.green {
  color: #11d811;
}

.coinPage-RouteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 100px;
  padding: 2px 10px 5px 10px;
  background-color: #3aafa9;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
}

.coinPage-RouteButton:active {
  text-decoration: none;
}

.coinPage-RouteButton:hover {
  transform: scale(1.1);
  transition: all ease-in-out 0.2s;
}
