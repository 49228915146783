.coinContainer {
  display: flex;
  justify-content: center;
}

.coinRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid #d7d7d7;
  background-color: transparent;
}

.coinData {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.coin {
  display: flex;
  align-items: center;
  padding-right: 24px;
}

.coin h1 {
  font-size: 20px;
  width: 150px;
  color: white;
  text-align: left;
  margin-left: 30px;
}

.coin img {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.coinSymbol {
  text-transform: uppercase;
  font-size: 20px;
  color: white;
  text-align: left;
  margin-left: 30px;
  margin-right: 20px;
  width: 50px;
}

.coinPrice {
  font-size: 20px;
  color: white;
  text-align: left;
  margin-left: 30px;
  text-align: left;
  width: 100px;
}
.coinVolume {
  font-size: 20px;
  color: white;
  text-align: left;
  margin-left: 30px;
  text-align: left;
  white-space: nowrap;
}

.coinData {
  display: flex;
  text-align: right;
  justify-content: space-between;
  width: 100%;
}

.coinPrice {
  width: 110px;
}

.coinVolume {
  width: 155px;
}

.priceChange {
  width: 90px;
  font-size: 20px;
  text-align: right;
}

.red {
  color: #f00606;
}

.green {
  color: #11d811;
}

.coin button {
  margin-left: 20px;
  height: 30px;
  width: 100px;
  padding: 0px 10px 0px 10px;
  background-color: #3aafa9;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.coin button:hover {
  transform: scale(1.1);
  transition: all ease-in-out 0.2s;
}
